/* react-phone-number-input overrides */

.rrui__select__button:active,
.rrui__select__button:hover,
.rrui__select__button:active .rrui__select__arrow,
.rrui__select__button:hover .rrui__select__arrow {
  color: #3e64a9;
}

.phone-input-field > div > div > div {
  height: inherit !important;
}

.phone-input-field input:hover,
.phone-input-field div > div > div > button:hover {
  border-bottom: 1px solid black;
}

.phone-input-field input:focus,
.phone-input-field div > div > div > button:focus {
  border-bottom: 1px solid #3e64a9;
}

.phone-input-field > .react-phone-number-input__row {
  width: 100%;
}

.phone-input-field.phone-input-field-admin > .react-phone-number-input__row {
  width: 256px;
}

.phone-input-field ul {
  z-index: 10;
  width: calc(100% - 10px) !important;
  margin: 0 5px auto !important;
  left: initial !important;
}
